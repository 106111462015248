<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">Create User Admin</h3>
              <div class="form-group">
                <label for="name"
                  >Email<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="type email.."
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <label for="status"
                  >Password<span class="text-danger">*</span> :</label
                >
                <div class="input-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control"
                    id="password"
                    placeholder="type password.."
                    required
                    v-model="password"
                    readonly
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="type password.."
                    required
                    v-model="password"
                    readonly
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroupPrepend2"
                      @click="toggleShow"
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-eye-slash': !showPassword,
                          'fa-eye': showPassword,
                        }"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="role"
                  >Role<span class="text-danger">*</span> :</label
                >
                <multiselect
                  v-model="role"
                  label="name"
                  track-by="name"
                  placeholder="type to find role"
                  open-direction="bottom"
                  :options="optionsRole"
                  :searchable="true"
                  :loading="isLoadingSearch"
                  :close-on-select="true"
                  @input="changeGroup"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="form-group">
                <label for="status">Group :</label>
                <b-form-select
                  class="form-control"
                  id="group"
                  v-model="group"
                  :options="optionsGroup"
                >
                </b-form-select>
              </div>
              <div class="form-group">
                <label for="vendor"
                  >Vendor<span class="text-danger">*</span> :</label
                >
                <multiselect
                  v-model="vendor"
                  label="name"
                  track-by="name"
                  placeholder="type to find vendor"
                  open-direction="bottom"
                  :options="items_vendor"
                  :searchable="true"
                  :loading="isLoadingSearch"
                  :close-on-select="true"
                  @search-change="asyncFindOrg"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="is_active"
                  v-model="isActive"
                />
                <label class="form-check-label" for="is_active"
                  >Is Active</label
                >
              </div>
              <button
                type="submit"
                class="btn btn-block btn-primary mt-5"
                @click="addUserAdmin"
              >
                <i class="fa fa-plus"></i>
                Create User Admin
              </button>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
const passwordDefault = process.env.VUE_APP_PASSWORD_DEFAULT_USERADMIN;
import constant from '../../store/constant';
export default {
  name: 'CreateUserAdmin',
  data() {
    return {
      email: '',
      password: passwordDefault,
      showPassword: false,
      vendor: [],
      isLoading: false,
      items_vendor: [],
      isLoadingSearch: false,
      role: [],
      optionsRole: [],
      group: 'ebook',
      optionsGroup: [
        { value: 'ebook', text: 'Ebook' },
        { value: 'publisher', text: 'Publisher' },
      ],
      isActive: true,
    };
  },
  computed: {
    ...mapState({
      successMessage: (state) => state.admin.successMessage,
      errorMessage: (state) => state.admin.errorMessage,
      itemRole: (state) => state.accessRole.items,
    }),
    buttonLabel() {
      return this.showPassword ? 'Show' : 'Hide';
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Create User Admin | Content Management System Ebooks Gramedia.com';
      },
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/user-admin');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    itemRole: function() {
      if (!this.errorMessage) return;
      this.optionsRole = this.itemRole;
    },
  },
  mounted() {
    this.fetchRole();
  },
  methods: {
    ...mapActions('admin', ['createUserAdmin']),
    ...mapActions('vendors', ['findVendor']),
    ...mapActions('accessRole', ['fetchAllRole']),

    fetchRole() {
      this.fetchAllRole().then((res) => {
        for (let item in res) {
          this.optionsRole.push(res[item]);
        }
      });
    },

    async addUserAdmin() {
      const payload = {
        email: this.email,
        password: this.password,
        vendor: this.vendor.id,
        role_id: this.role.id,
        group: this.group,
        is_active: this.isActive,
      };
      this.createUserAdmin(payload);
    },

    asyncFindOrg(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          q: keyword,
        };
        this.findVendor(payload)
          .then((resp) => {
            this.items_vendor = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    changeGroup() {
      if (constant.ALLOWED_ROLE_WITHOUT_VENDOR.includes(this.role.id)) {
        this.group = 'ebook';
      } else {
        this.group = 'publisher';
      }
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
